import React, { useEffect, useState } from "react";
import './Contact.scss';
export default function Contact() {
    return (
        <div id="contact">
            <h2 className="section-title">
                Contact Me
            </h2>
            <div className="container">
                <a className="item" href="https://www.linkedin.com/in/tommy-lai-991303193/" target="_blank">
                    <img src="/linkedin.svg" alt="linkedin" />
                </a>
                {/* <a className="item">
                    <img src="/instagram.svg" alt="instagram" />
                </a> */}
                {/* <a className="item">
                    <img src="/twitter.svg" alt="twitter" />
                </a> */}
                <a className="item" href="https://github.com/tommylai088" target="_blank">
                    <img src="/github.svg" alt="github" />
                </a>
            </div>
        </div>
    );
}