
export const handleClickScroll = (id: string) => {
    const nav = document.querySelector('nav');
    const element = document.getElementById(id);
    if (element && nav) {
        let navHeight = nav.getBoundingClientRect().height;
        let elementOffsetTop;
        if (window.pageYOffset == 0) {
            elementOffsetTop = element.offsetTop - (navHeight * 2)
        } else {
            elementOffsetTop = element.offsetTop - navHeight;
        }
        window.scrollTo({ top: elementOffsetTop, behavior: 'smooth' });
    }
};