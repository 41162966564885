import React, { useEffect, useState } from "react";
import { handleClickScroll } from "utils/app-util";
import './Navbar.scss';
export default function Navbar() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeMobileMenu, setActiveMobileMenu] = useState<boolean>(false);

    const navItems = [
        {
            name: 'HOME',
            id: 'home'
        },
        {
            name: 'ABOUT',
            id: 'about'
        },
        {
            name: 'PORTFOLIO',
            id: 'portfolio'
        },
        {
            name: 'CONTACT',
            id: 'contact'
        },
    ];

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset)
        }

        window.addEventListener('scroll', updatePosition)

        updatePosition()

        return () => window.removeEventListener('scroll', updatePosition)
    }, []);

    return (
        <nav className={`navbar-container ${scrollPosition > 0 ? 'sticky' : ''} ${activeMobileMenu ? 'is-mobile' : ''}`}>
            <span>Tommy Lai</span>
            <span className="menu-icon" onClick={() => {
                setActiveMobileMenu(prevState => !prevState);
            }}>
                {scrollPosition > 0 ? (
                    <img src="/menu-black.svg" />
                ) : <img src="/menu.svg" />}
            </span>
            <ul>
                {navItems.map(item => (
                    <li
                        key={item.id}>
                        <a onClick={() => {
                            handleClickScroll(item.id);
                            setActiveMobileMenu(false);
                        }}>
                            {item?.name}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
}