import React, { useEffect, useState } from "react";
import './Portfolio.scss';
export default function Portfolio() {
    return (
        <div id="portfolio">
            <div>
                <p className="section-subtitle">
                    What I Did ?
                </p>
                <h2 className="section-title">
                    Portfolio
                </h2>
            </div>
            <div className="container">
                <div className="item">
                    <span className="title">
                        Anime demo
                    </span>
                    <p className="description">This website uses Next.js 14 for the frontend and MongoDB for the backend. I used the Bootstrap library for the layout and deployed it with Vercel.</p>
                    <a href="https://anime-nextjs-demo.vercel.app/" target="_blank" className="visitBtn">
                        Visit
                    </a>
                </div>
                <div className="item">
                    <span className="title">
                        Chat room demo
                    </span>
                    <p className="description">This website uses Next.js 14 for the frontend, with NestJS and MongoDB for the backend. The layout is built using the Chakra UI library and deployed on Vercel, with WebSocket integration for real-time functionality.</p>
                    <a href="https://chat-room-app-nextjs.vercel.app/" target="_blank" className="visitBtn">
                        Visit
                    </a>
                </div>
                {/* <div className="item">
                    <img src="/folio-1.jpg" alt="folio-3" />
                </div> */}
            </div>
        </div>
    );
}