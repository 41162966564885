import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.scss';
import Navbar from './components/Navbar/Navbar';
import Home from 'components/Home/Home';
import About from 'components/About/About';
import Portfolio from 'components/Portfolio/Portfolio';
import Contact from 'components/Contact/Contact';

function App() {
  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, [])
  return (
    <main>
      <Navbar />
      <Home />
      <About />
      <Portfolio />
      <Contact />
    </main>
  );
}

export default App;
