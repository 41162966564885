import React, { useEffect, useRef, useState } from "react";
import './Home.scss';
import Typed from 'typed.js';
import { handleClickScroll } from "utils/app-util";

export default function Home() {
    const nameRef = useRef(null);
    const jobPositionRef = useRef(null);

    useEffect(() => {
        const typed = new Typed(nameRef?.current, {
            strings: ['Tommy Lai'],
            typeSpeed: 50,
        });
 
        return () => {
            typed.destroy();
        }
    }, []);

    useEffect(() => {
        const typed = new Typed(jobPositionRef?.current, {
            strings: ['Frontend Developer', 'Web Developer', 'Full Stack Developer'],
            typeSpeed: 100,
            loop: true,
            backSpeed: 50
        });
        return () => {
            typed.destroy();
        }
    }, [])

    return (
        <div id="home">
            <div className="intro-box">
                <h1>Hi, I'm <span ref={nameRef}></span></h1>
                <h1>
                    <span ref={jobPositionRef}></span>
                </h1>
                <div
                    className="view-more-btn"
                    onClick={() => handleClickScroll('about')}
                >
                    <img src="/expand_more.svg" alt="expand_more" />
                </div>
            </div>
        </div>
    );
}