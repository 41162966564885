import React from "react";
import './About.scss';

export default function About() {
  return (
    <div id="about">
      <div className="container">
        <div className="flex">
          {/* <div className="about-img-holder">
            <img src="/me.jpeg" className="about-img" alt="me" />
          </div> */}
          <div className="about-caption">
            <p className="section-subtitle">Who Am I ?</p>
            <h2 className="section-title">About Me</h2>
            <p className="description">
              My name is Tommy, and I'm from Hong Kong. I'm a Frontend Developer with 6 years of experience in web development. I specialise in creating responsive and dynamic websites using React, Angular. Additionally, I have 2 years of backend experience in Java, which gives me a comprehensive understanding of full-stack development. I'm passionate about crafting high-quality, scalable web applications that provide excellent user experiences.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}